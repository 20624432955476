import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-grid-system";
import { useStaticQuery, graphql } from "gatsby";
import Select from "react-select";
import CheckVerder from "../../assets/images/icono-mensaje-exito.svg";
import Cerrar from "../../assets/images/x-cerrar.svg";
import { PopupModal } from "react-calendly";
import { useForm } from "@formspree/react";
import useLang from "../commons/useLang";
////---------Styles-------------////
import "./styles.scss";
const Contacto = () => {
  const data = useStaticQuery(graphql`
    query getContactoTexts {
      allContentfulFormulario {
        nodes {
          title
          text
          button
          formulario {
            labelEmail
            labelMensaje
            labelMotivo
            labelNombre
            labelTel
            placeholderEmail
            placeholderMotivo
            placeholderNombre
            placeholderTel
          }
          successMessage
        }
      }
    }
  `);
  useEffect(() => {
    document.body.style.overflow = "visible";
  }, []);
  const [currentLang] = useLang();
  const [formState, handleSubmit] = useForm("mjvlopgq");
  const [isPopupOpen, setIsOpenPopup] = useState(false);
  const [isCalendlyPopupOpen, setIsCalendlyPopupOpen] = useState(false);
  const contactoText = data.allContentfulFormulario.nodes[currentLang];
  const options = [
    { value: "mobileFarma", label: "MobileFarma" },
    { value: "portalECommerce", label: "Portal e-Commerce" },
    { value: "mobileIBMerchan", label: "MobileIB Merchan" },
    { value: "googleDataStudio", label: "Google Data Studio" },
  ];
  const [selectedOption, setSelectedOption] = useState([]);

  const isButtonDisabled = isPopupOpen || selectedOption.length === 0;

  const customStyles = {
    placeholder: () => ({
      fontSize: "1rem",
      lineHeight: "2",
      margin: "0 0.8rem",
    }),
    valueContainer: () => ({
      display: "flex",
      color: "#595958",
      width: "85%",
    }),
    control: (provided) => ({
      ...provided,
      flexWrap: "nowrap",
    }),
  };
  useEffect(() => {
    if (formState?.succeeded) {
      setIsOpenPopup(true);
      document.querySelector("#contactForm").reset();
      setSelectedOption([]);
    }
  }, [formState?.succeeded]);

  const [rootTag, setRootTag] = useState(false);

  useEffect(() => setRootTag(document.querySelector("#___gatsby")));

  const splitText = contactoText.text.split("|");

  return (
    <>
      <section className="contacto-container">
        {isPopupOpen ? (
          <div className="small--popup">
            <button
              className="small--popup__close"
              onClick={() => setIsOpenPopup(false)}
            >
              <img src={Cerrar} />
            </button>
            <img src={CheckVerder} className="check-verde " />
            <p>{contactoText.successMessage}</p>
          </div>
        ) : null}
        <Container>
          <Row>
            <Col className="text-form" lg={5} offset={{ lg: 1 }}>
              <h1>{contactoText.title}</h1>
              <p>{splitText[0]}</p>
              <p>
                <span>{splitText[1]}</span>
                <a onClick={() => setIsCalendlyPopupOpen(true)}>
                  {splitText[2]}
                </a>
                <PopupModal
                  url="https://calendly.com/gciacomercial/alejandro"
                  onModalClose={() => setIsCalendlyPopupOpen(false)}
                  open={isCalendlyPopupOpen}
                  rootElement={rootTag}
                />
              </p>
            </Col>
            <Col lg={5} offset={{ lg: 1 }}>
              <form onSubmit={handleSubmit} id="contactForm">
                <label>
                  <div className="asterisco">
                    {contactoText.formulario.labelNombre}
                    <span>*</span>
                  </div>
                  <input
                    type="text"
                    placeholder={contactoText.formulario.placeholderNombre}
                    required
                    name="nombre"
                  ></input>
                </label>
                <div className="mail-tel">
                  <label>
                    <div className="asterisco">
                      {contactoText.formulario.labelEmail}
                      <span>*</span>
                    </div>
                    <input
                      type="email"
                      placeholder={contactoText.formulario.placeholderEmail}
                      required
                      name="email"
                    ></input>
                  </label>
                  <label>
                    <div className="asterisco">
                      {contactoText.formulario.labelTel}
                    </div>
                    <input
                      type="number"
                      placeholder={contactoText.formulario.placeholderTel}
                      name="telefono"
                    ></input>
                  </label>
                </div>
                <label>
                  <div className="asterisco">
                    {contactoText.formulario.labelMotivo}
                    <span>*</span>
                  </div>
                  <Select
                    isMulti
                    options={options}
                    placeholder={contactoText.formulario.placeholderMotivo}
                    required
                    styles={customStyles}
                    defaultValue={selectedOption}
                    onChange={setSelectedOption}
                  />
                </label>
                <label className="text-area">
                  <div className="asterisco">
                    {contactoText.formulario.labelMensaje}
                  </div>
                  <textarea
                    name="message"
                    id=""
                    cols="30"
                    rows="5"
                    placeholder={contactoText.formulario.placeholderMensaje}
                  ></textarea>
                </label>
                <button className="button-form" disabled={isButtonDisabled}>
                  {contactoText.button}
                </button>
              </form>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Contacto;
